<template>
  <div v-if="navObj.list.length >0" class="footer-fix">
    <div class="d-flex " :class="{'just-two':navObj.list.length == 2}">
      <div v-for="(item,index) in navObj.list " class="-btn-container">
        <a class="btn btn-lg -btn" @click="itemClick(item.detail,index)">
          <img class="-icon img-fluid" :src="comApi.getNormalImg(item.selectedIcon)">
          <div class="-typo">{{t(item.detail.label)}}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const listMap = {
  home: {
    label: 'Game page',
    router: '/',
  },
  vip: {
    label: 'Vip',
    needLogin: true,
    router: '/vip',
  },
  promotion: {
    label: 'Promote',
    router: '/promotion',
  },
  user: {
    label: 'Account',
    needLogin: true,
    router: '/member/profile/personal',
  },
  charge: {
    label: 'Deposit',
    needLogin: true,
    router: '/member/cashier/deposit/depositInfo',
  },
  withdraw: {
    label: 'Withdraw',
    needLogin: true,
    router: '/member/cashier/withdrawal/withdrawalInfo',
  },
}

const navObj = reactive({
  index: 0,
  oldIndex: 0,
  list: getList()
})

function getList() {
  let list = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (info.type == "bottomNavigation" && info.open) {
      if (listMap[info.key]) {
        info.detail = listMap[info.key];
      }
      list.push(info);
    }
  }
  list.sort((a, b) => {
    return a.sort - b.sort
  })
  list.splice(3);//最多三个，盘的样式不一
  log.info('bottomNavigation-list', list);
  return list
}

function itemClick(item, index) {
  if (item.needLogin) {
    if (!isLogged.value) {
      bus.emit(bus.event.loginState, true);
      return;
    }
  }
  if (item.router) {
    router.push({
      path: item.router,
    });
  }
}
</script>

<style lang="scss" scoped>
.footer-fix {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  z-index: 1000;

  .d-flex {
    display: flex !important;

    .-btn-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      .btn {
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 0;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        .-typo {
          color: #333;
          font-size: 1.1rem;
        }
      }
    }

    .-btn-container:first-child {
      border-top-left-radius: 30px;

      .btn {
        background: linear-gradient(180deg, #0071e3, #0071e3);
        border-top-left-radius: 40px;
      }
    }

    .-btn-container:nth-child(2) {
      z-index: 1;

      .btn {
        background: radial-gradient(100% 100% at 50.02% 0, #fff 0, #fff 100%);
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding: 0;
        height: 80px;

        .-icon {
          width: 90px;
          height: auto;
          -webkit-animation: x-jello-horizontal 3s infinite both;
          animation: x-jello-horizontal 3s infinite both;
          transform: translateX(0);
        }

        @keyframes x-jello-horizontal {
          0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }

          5% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
          }

          7.5% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
          }

          10% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
          }

          15% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
          }

          20% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
          }

          25%,
          100% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }

        .-typo {
          position: relative;
          font-size: 1.1rem;
          font-weight: 600;

          display: inline-block;
          color: #000;
          background: #000 -webkit-gradient(linear, 100% 0, 0 0, from(#000), color-stop(0.5, #f22662), to(#fff));
          background-position: -200px top;
          background-repeat: no-repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-animation-name: x-shimmer;
          animation-name: x-shimmer;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-duration: 1.5s !important;
          animation-duration: 1.5s !important;
        }

        @keyframes x-shimmer {
          0% {
            background-position: -200px top;
          }

          100% {
            background-position: 200px top;
          }
        }
      }
    }

    .-btn-container:last-child {
      border-top-right-radius: 30px;

      .btn {
        background: linear-gradient(180deg, #ff8a00, #ff8a00);
        border-top-right-radius: 40px;
      }
    }

    .-btn-container:first-child,
    .-btn-container:last-child {
      .btn {
        height: 58px;
        padding: 0;

        .-icon {
          width: 60px;
          margin-top: -2rem;
        }

        .-typo {
          position: relative;
          font-weight: 500;
        }
      }
    }
  }
  .just-two {
    .-btn-container:nth-child(2) {
      z-index: 1;
      .btn {
        border-top-left-radius: 0px;
      }
    }
  }
}
</style>