<template>
  <div class="download">
    <div class="app-landing-content com-container">
      <div class="left-container">
        <div class="header-container">
          <div class="header-1">
            <div class="header-1-title">
              <p>{{t('游戏的未来就在这里')}}</p>
              <p>{{t('任何设备都能运行的好游戏')}}</p>
              <p>{{t('全新应用')}}</p>
            </div>
            <div>
              <img loading="lazy" :src="comApi.getLogoImg()" alt=""></div>
          </div>
          <div class="header-2">{{t('现在注册以获得无限奖励、优惠和奖品.')}}</div>
        </div>
        <div class="app-download-container">
          <div class="download-item">
            <vue-qr :logo-src="comApi.getLogoImg()" class="qr-img" :text="lobbyInfo.VersionGet.android.UrlPath" :size="100" :margin='10'>
            </vue-qr>
            <div class="os-name" @click="toDownClick()">{{t('安卓')}}</div>
            <div class="instruction-btn">{{t('操作说明')}}</div>
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="ad-container ad-1">
          <div class="ad-box ad-box-1">
            <p class="ad-title">{{t('轻松推荐')}}</p>
            <p class="ad-desc">{{t('分享推荐链接，赚取佣金')}}</p>
          </div>
          <div class="ad-box ad-box-2">
            <p class="ad-title">{{t('无缝钱包转账')}}</p>
            <p class="ad-desc">{{t('几秒钟内完成转账')}}</p>
          </div>
        </div>
        <div class="ad-container ad-2">
          <div class="ad-box ad-box-3">
            <p class="ad-title">{{t('更高安全系数')}}</p>
            <p class="ad-desc">{{t('通过面部识别或指纹登入账户')}}</p>
          </div>
          <div class="ad-box ad-box-4">
            <p class="ad-title">{{t('快速登录')}}</p>
            <p class="ad-desc">{{t('在安全、快捷的方式下保持登录状态')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
function back() {
  router.go(-1);
}

function toDownClick() {
  $act.openPage(lobbyInfo.value.VersionGet.android.UrlPath);
}

</script>

<style lang="scss" scoped>
.download {
  margin: 0 !important;
  background: #f3f5f6;
  position: relative;
  .app-landing-content {
    display: flex;
    word-break: break-word;
    padding: 18px 0 22px;
    margin: 0 auto;
    .left-container {
      width: 591px;
      margin: 63px 8px 0 0;
      .header-container {
        .header-1 {
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          font-size: 36px;
          color: #0f161f;
          width: 100%;
          div {
            width: 12%;
            img {
              margin: 10px 0 0;
              width: 100%;
            }
          }
          .header-1-title {
            width: 88%;
          }
        }
        .header-2 {
          margin: 36px 0 0;
          font-size: 14px;
          color: #8697a2;
        }
      }
      .app-download-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0 0;
        .download-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .qr-img {
            width: 120px;
            height: 120px;
          }
          .os-name {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 134px;
            background: #f3f6f6;
            color: #0f161f;
            border-radius: 20px;
            margin: 16px 0 0;
            font-size: 14px;
            cursor: pointer;
            padding: 5px;
          }
          .instruction-btn {
            margin: 10px 0 0;
            color: #06c;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
    .right-container {
      display: flex;
      justify-content: space-between;
      width: 713px;
      height: 778px;
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 50%;
      background-position-x: -0%;
      background-position-y: 50%;
      padding: 0 0 0 288px;
      color: #fff;
      background-image: url("@/assets/pc/down/mobile.png");
      .ad-container {
        .ad-box {
          display: flex;
          flex-direction: column;
          width: 206px;
          height: 277px;
          border-radius: 15px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          padding: 175px 15px 0 20px;
          .ad-title {
            font-size: 18px;
          }
          .ad-desc {
            margin: 8px 0 0;
            font-size: 14px;
          }
        }
        .ad-box:not(:first-child) {
          margin: 14px 0 0;
        }
        .ad-box-1 {
          background-image: url("@/assets/pc/down/referral-made-easy.jpg");
        }
        .ad-box-2 {
          background-image: url("@/assets/pc/down/seamless-wallet-transfer.jpg");
        }
        .ad-box-3 {
          background-image: url("@/assets/pc/down/higher-security.jpg");
        }
        .ad-box-4 {
          background-image: url("@/assets/pc/down/express-login.jpg");
        }
      }
      .ad-2 {
        margin: 112px 0 0;
      }
    }
  }
}
</style>
